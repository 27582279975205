<template>
  <div>
    <!--收入概况 网校学员-->
    <div class="flex-center mt20 function-views">
      <div class="pie">
        <div class="title flex-center">
          收入概况
          <helpIcon style="margin-left:6px;" prompt="统计网校累计收入的金额，按今日、本月、上月统计，包括课程销售额和直播打赏送礼的收益"></helpIcon>
        </div>
        <div class="pie-contain">
          <pieChart :pieData="list1"></pieChart>
        </div>
        <div class="bottom-price">
          <div class="item">
            <span class="vm text">总收入：{{ profitBasic.totalProfit }}元</span>
            <span class="vm text">今日：{{ profitBasic.todayTotalProfit }}元</span>
          </div>
          <div class="item">
            <span class="vm text">本月：{{ profitBasic.theMonthTotalProfit }}元</span>
            <span class="vm text">上月：{{ profitBasic.lastMonthTotalProfit }}元</span>
          </div>
        </div>
      </div>
      <div class="pie">
        <div class="title flex-center">
          网校学员
          <helpIcon style="margin-left:6px;" prompt="统计网校累计注册的用户数量，按今日、本月、上月统计，包括付费学员、未付费学员和已拉黑的学员"></helpIcon>
        </div>
        <div class="pie-contain">
          <pieChart :pieData="list2"></pieChart>
        </div>
        <div class="bottom-price">
          <div class="item">
            <span class="vm text">总学员：{{ studentBasic.student_num }}人</span>
            <span class="vm text">今日：{{ studentBasic.todaySN }}人</span>
          </div>
          <div class="item">
            <span class="vm text">本月：{{ studentBasic.theMonthSN }}人</span>
            <span class="vm text">上月：{{ studentBasic.lastMonthSN }}人</span>
          </div>
        </div>
      </div>
    </div>
    <!--网校课程 课程订单-->
    <div class="flex-center mt20 function-views">
      <div class="pie">
        <div class="title flex-center">
          网校课程
          <helpIcon style="margin-left:6px;" prompt="统计网校累计创建课程的数量，包括已上架、已下架和已隐藏的课程(不包括删除的课程)"></helpIcon>
        </div>
        <div class="pie-contain">
          <pieChart :pieData="list3"></pieChart>
        </div>
        <div class="bottom-price">
          <div class="item">
            <span class="vm text">总课程：{{ courseBasic.course_num }}门</span>
            <span class="vm text">已上架：{{ courseBasic.upper_course_num }}门</span>
          </div>
          <div class="item">
            <span class="vm text">已下架：{{ courseBasic.lower_course_num }}门</span>
            <span class="vm text">已删除：{{ courseBasic.deleted_course_num }}门</span>
          </div>
        </div>
      </div>
      <div class="pie">
        <div class="title flex-center">
          课程订单
          <helpIcon
            style="margin-left:6px;"
            prompt="统计网校累计已支付的订单数量，按今日、本月、上月统计，包括支付成功、已作废和已过期的订单(不包括未支付的订单)"
          ></helpIcon>
        </div>
        <div class="pie-contain">
          <pieChart :pieData="list4"></pieChart>
        </div>
        <div class="bottom-price">
          <div class="item">
            <span class="vm text">总订单：{{ orderBasic.course_order_num }}笔</span>
            <span class="vm text">今日：{{ orderBasic.today_course_order_num }}笔</span>
          </div>
          <div class="item">
            <span class="vm text">本月：{{ orderBasic.the_month_course_order_num }}笔</span>
            <span class="vm text">上月：{{ orderBasic.last_month_course_order_num }}笔</span>
          </div>
        </div>
      </div>
    </div>
    <!--学习人次占比-->
    <div class="flex-center mt20 function-views">
      <div class="pie">
        <div class="title flex-center">
          学习人次占比
          <helpIcon
            style="margin-left:6px;"
            prompt="统计网校学员学习不同课程类型的人次占比(单日内去重，多日重复累计；比如同一个章节同一个学员在每日23时59分内重复学习只算1个人次)"
          ></helpIcon>
        </div>
        <div class="pie-contain">
          <pieChart :pieData="list5"></pieChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'

export default {
  name: 'overview',

  components: {
    pieChart,
    helpIcon
  },

  data() {
    return {
      // 收入概况
      list1: [],
      // 网校学员
      list2: [],
      // 网校课程
      list3: [],
      // 课程订单
      list4: [],
      // 学习人次占比
      list5: [],

      uv: {},
      studentBasic: {},
      profitBasic: {},
      orderBasic: {},
      courseBasic: {}
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.$http({
        url: '/statistics/totalBaseData',
        callback: ({ data: { uv, studentBasic, profitBasic, orderBasic, courseBasic } }) => {
          this.uv = uv
          this.studentBasic = studentBasic
          this.profitBasic = profitBasic
          this.orderBasic = orderBasic
          this.courseBasic = courseBasic

          // 收入概况
          this.list1 = [
            {
              value: profitBasic.course_order_amount,
              name: '课程收入',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: profitBasic.gift_order_amount,
              name: '直播打赏',
              itemStyle: { color: '#FED74C' }
            }
          ]
          // 网校学员
          this.list2 = [
            {
              value: studentBasic.student_pay_num,
              name: '付费学员',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: studentBasic.student_unpayed_num,
              name: '未付费学员',
              itemStyle: { color: '#FED74C' }
            }
          ]
          // 网校课程
          this.list3 = [
            {
              value: courseBasic.live_course_num,
              name: '直播课',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: courseBasic.recording_course_num,
              name: '录播课',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: courseBasic.private_course_num,
              name: '小班课',
              itemStyle: { color: '#66B358' }
            },
            {
              value: courseBasic.series_course_num,
              name: '系列课',
              itemStyle: { color: '#FA6400' }
            }
          ]
          // 课程占比
          this.list4 = [
            {
              value: orderBasic.payed_order_num,
              name: '支付成功',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderBasic.unpayed_order_num,
              name: '未支付',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: orderBasic.invalid_order_num,
              name: '已作废',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderBasic.overdue_order_num,
              name: '已过期',
              itemStyle: { color: '#FA6400' }
            }
          ]
          // 课程占比
          this.list5 = [
            {
              value: uv.live_uv_num,
              name: '普通模式',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: uv.fast_live_uv,
              name: '极速模式',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: uv.private_class_uv,
              name: '小班直播',
              itemStyle: { color: '#66B358' }
            },
            {
              value: uv.video_uv,
              name: '录播',
              itemStyle: { color: '#FA6400' }
            },
            {
              value: uv.live_playback_uv,
              name: '回放',
              itemStyle: { color: '#1B9D97' }
            }
          ]
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.pie {
  flex: 1;
  height: 328px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  .pie-contain {
    flex: 1;
    margin-bottom: 20px;
    position: relative;
  }
  .bottom-price {
    .item {
      width: 60%;
      font-size: 0;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .text {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        color: #666;
      }
    }
  }
}
.function-views {
  flex-wrap: wrap;
  > div:nth-child(2n) {
    margin-left: 20px;
  }
}
</style>
